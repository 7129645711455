import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';

import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  const language = navigator.language.split(/[-_]/)[0];
  return <LanguagesWrapper>
    <LanguagesSelectElement>
      <LanguagesSelect onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        <LanguagesOption selected={language == 'fr'} value="fr">Fr</LanguagesOption>
        <LanguagesOption selected={language == 'en'} value="en">En</LanguagesOption>
      </LanguagesSelect>
    </LanguagesSelectElement>
  </LanguagesWrapper>;
}

export default Languages;
